import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { device } from '../utils/breakpoints';
import { GoArrowRight } from 'react-icons/go';

const Title = styled.h2`
  font-weight: var(--text-bold);
  margin-bottom: 24px;
`;

// const Subtitle = styled.h4`
//   margin-top: 0.5rem;
//   color: var(--color-gray-700);
//   margin-bottom: 0;
// `

const Article = styled.article`
  margin: 48px 0;

  .read-more {
    transition: all 300ms ease 150ms;
  }

  @media ${device.medium} {
    margin-right: 64px;
  }

  &:hover > ${Title} {
    color: var(--color-primary);
  }
  &:hover > .read-more {
    color: var(--color-primary);
    margin-left: 8px;
    /* transform: translateX(8px); */
  }
  &:hover > .read-more::after {
    content: '>>';
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin-left: 4px;
  }

  p {
    font-size: 0.9rem;
  }

  ${(props) =>
    props.published === false
      ? `border-left: 5px solid var(--color-primary);
          padding-left: 10px;`
      : ''};
`;

const ProjectCard = ({ title, date, slug, excerpt, image }) => {
  return (
    <Link to={slug}>
      <Article className="project-card">
        <div className="project-image-wrapper">
          <img src={image} alt={title} load="lazy" />
        </div>
        <Title>{title}</Title>
        {/* <Subtitle>Or how I </Subtitle> */}
        <p>{excerpt}</p>
        <span className="read-more">Read More</span>
      </Article>
    </Link>
  );
};

export default ProjectCard;
