import React from 'react';

import { graphql } from 'gatsby';

import Footer from '../components/Footer/Footer';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import styled from 'styled-components';
import SEO from '../components/SEO';
import ProjectCard from '../components/ProjectCard';
import Header from '../components/Header/header';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export default ({ data }) => {
  const cards = data.allMdx.edges.map((p) => {
    return (
      <ProjectCard
        title={p.node.frontmatter.title}
        slug={p.node.fields.slug}
        image={p.node.frontmatter.image.publicURL}
        date={p.node.frontmatter.date}
      />
    );
  });
  return (
    <>
      <SEO title="Games"></SEO>
      <Header />
      <MaxWidthWrapper>
        <BodyWrapper>
          <div className="project-card-list">{cards}</div>
        </BodyWrapper>
      </MaxWidthWrapper>
      <Footer></Footer>
    </>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { collection: { eq: "projects" } }
        frontmatter: { type: { eq: "game" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            published
            image {
              publicURL
            }
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`;
